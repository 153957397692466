<mat-form-field
  appearance="outline"
  class="color-picker w-100 {{ fieldClass }}"
  [formGroup]="form"
  [ngClass]="{
    'ng-invalid mdc-text-field--invalid':
      colorFormControl.touched && colorFormControl.invalid
  }"
>
  <mat-label>{{ label | translate }}</mat-label>
  <input
    matInput
    [ngModel]="chosenColor"
    [ngModelOptions]="{ standalone: true }"
    readonly
  />
  <ngx-colors
    class="suffix"
    matSuffix
    ngx-colors-trigger
    [format]="'hex'"
    [formControlName]="colorFormControlName"
  ></ngx-colors>
</mat-form-field>
